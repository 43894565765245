<template>
      <transition name="slide">
        <div v-if="isShow" class="mobile-page__wrapper">
            <div class="container has-space">
                <div class="mobile-page__header">
                    <div class="mobile-account__header-back" @click="isShow = false"> <img src="../../../static/images/icons/icon_arrow_left_white_m.png"></div>
                    <div class="mobile-page__header-title">{{ $t(title) }}</div>
                    <div v-if="isShowTick" class="mobile-page__header-tick" :class="{'selected': isSelect}" @click="handleFinishSelect()"> </div>
                </div>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        isSelect: {
            type: Boolean,
            default: false
        },
        isShowTick: {
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            isShow: false
        }
    },
    methods: {
        handleOpen() {
            this.isShow = !this.isShow;
        },

        handleClose() {
            this.isShow = false;
        },

        handleFinishSelect() {
            this.$emit('finishSelectEvent',this.selectedItem);
            this.handleClose();
        },
    },

}
</script>

<style>
.mobile-page__wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-theme);
    z-index: 10;
    overflow-y: auto;
}
.mobile-page__header{
    padding: 1rem 0;
    display: flex;
    align-items: center;
}
.mobile-page__header-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
}
.mobile-page__header-title{
    padding-right: 1rem;
    flex: 1;
    text-align: center;
}
.mobile-page__header-tick{
    width:1rem;
    height:1rem;
    background: url('../../../static/images/icons/icon_tick_unselect.png') center/cover no-repeat;
}
.mobile-page__header-tick.selected{
    background: url('../../../static/images/icons/icon_tick_selected.png') center/cover no-repeat;
}
</style>