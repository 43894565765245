<template>
    <div class="display-flex-center mb-1rem pointer" :class="{'disabled': disabled}" @click="handleClick()">
        <div class="tick-wrapper selected" v-if="isSelect" ></div>
        <div class="tick-wrapper" v-else :class="{'selected': isSelectSelf , 'disabled': disabled}" ></div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        className: {
            type: String
        },
        isSelect: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isSelect() {
         this.setIsSelectData();
        },

        disabled() {
            if (this.disabled) {
                this.isSelectSelf = false;
            }
            
        }
    },
    data() {
        return {
            isSelectSelf: false
        }
    },
    mounted() {
        this.setIsSelectData();
    },
    methods: {
        setIsSelectData() {
            this.isSelectSelf = this.isSelect
        },

        handleClick() {        
            this.isSelectSelf = !this.isSelectSelf;
            this.$emit('changeTickEvent',this.isSelectSelf);
        }
    },
}
</script>

<style scoped>
.disabled{
    pointer-events: none;
}
.tick-wrapper{
    cursor: pointer;
    width:1.1rem;
    height:1.1rem;
    margin-right:.5rem;
    background: url('../../../static/images/icons/icon_tick_white_unselect.png') center/cover no-repeat;
}
.disabled .tick-wrapper{
     background: url('../../../static/images/icons/icon_tick_white_disabled.png') center/cover no-repeat;

}

.tick-wrapper.selected{
    background: url('../../../static/images/icons/icon_tick_white_selected.png') center/cover no-repeat;
}
</style>